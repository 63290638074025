<template>
  <div class="gallery-hldr clearfix" :class="{'p-1 m-0':parent === 'booking'}">
    <div id="gallery" class="content">
      <agile class="main slideshow-container" ref="main" :options="options" @after-change="afterChange">
        <div class="slide" v-for="(slide, index) in slideList"
        :key="index" :class="parent === 'booking'? `from-booking slide--${index}`: `slide--${index}`">
          <img v-bind="mainProps" :src="slide" :alt="hotelName" @error="showDefaultImage">
        </div>

        <template slot="prevButton">
          <i class="fas fa-chevron-right"></i>
        </template>
        <template slot="nextButton">
          <i class="fas fa-chevron-left"></i>
        </template>
      </agile>

      <div class="thumbnails" v-if="slideList.length > 1 && parent === 'product'">
        <div class="slide--thumbniail" :class="{'slide--thumbniail-active': index === currentIndex}" v-for="(slide, index) in slideList"
          :key="index" @click="$refs.main.goTo(index)">
          <img v-bind="mainProps" :src="slide" :alt="hotelName" @error="showDefaultImage">
        </div>
      </div>

      <agile class="thumbnails from-booking" dir="ltr"
      v-if="parent === 'booking'" :options="options2" ref="thumb">
        <div class="slide--thumbniail" :class="{'agile__slide--active': index === currentIndex}"
        v-for="(slide, index) in slideList" :key="index" @click="$refs.main.goTo(index);$refs.thumb.goTo(index)">
          <img :src="slide" :alt="hotelName" @error="showDefaultImage">
        </div>
      </agile>

    </div>
  </div>
</template>
<script>
import TemplateProductSlider from './TemplateProductSlider';

export default {
  mixins: [TemplateProductSlider],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
};
</script>

<style scoped>
  @media (max-width: 479px){
    .holiday-hotel-details, .facilitie-list{
      display: none;
    }
  }
</style>

<style lang="sass">

.thumbnails
  clear: both
  margin: 0
  padding: 0
  height: 210px
  overflow-x: auto

.thumbnails.from-booking
  width: calc(100% + 10px)
  height: auto
  margin: 0 -5px

  .slide--thumbniail
    margin: 0
    padding: 0
    img
      cursor: pointer
      height: 55px
      padding: 3px 3px
      transition: opacity 0.3s
      width: 100%
      max-width: fit-content
  .agile__slide--active
    opacity: 1

// Basic VueAgile styles
.agile
  &__nav-button
    background: transparent
    top: calc(50% - 20px)
    border: none
    color: #fff
    cursor: pointer
    font-size: 40px
    transition-duration: .3s

    &--prev
      right: 0
      left: auto
    &--next
      left: 0
      right: auto

    .thumbnails &
      position: absolute
      top: 50%
      transform: translateY(-50%)

      &--prev
        left: -45px

      &--next
        right: -45px

    &:hover
      color: #ccc
      box-shadow: none

  &__dot
    margin: 0 10px

    button
      background-color: #eee
      border: none
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #888

// Slides styles
.slide.from-booking
  height: 150px

#gallery .slide
  align-items: center
  box-sizing: border-box
  color: #fff
  display: flex
  height: auto
  justify-content: center

  &--thumbniail
    cursor: pointer
    transition: all .3s
    margin: 5px 2px
    padding: 2px
    display: inline-flex
    border: 1px solid #ccc
    opacity: .5

    &:hover
      box-shadow: gray 1px 1px 10px

    img
      height: 60px
      max-width: 99px

    &-active
      opacity: 1

  img
    height: 210px
    object-fit: cover
    object-position: center
    width: 100%

@media (max-width: 479px)
  .main
    margin-bottom: 0

    .slide
      height: 200px !important

  .thumbnails
    height: 80px

    .slide--thumbniail
      width: 18.5%
      height: auto
      margin: 1px

      &-active
        border: 2px solid black
        padding: 1px

      img
        max-width: 100%
        width: 100%
        height: 30px !important

@media (min-width: 479px)
  #gallery .slide img
      height: 450px !important

</style>
