export default {
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    parent: {
      type: String,
      default: 'product',
    },
    hotelName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {
        dots: false,
        fade: true,
        navButtons: true,
        rtl: true,
        speed: 500,
        slidesToshow: 8,
      },
      options2: {
        dots: false,
        rtl: true,
        centerMode: true,
        navButtons: false,
        slidesToShow: 3,
      },
      slideList: [],
      currentIndex: 0,
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    };
  },
  beforeMount() {
    const s = [
      'assets/img/search/hotel2.jpg',
      'assets/img/search/hotel3.jpg',
      'assets/img/search/hotel4.jpg',
      'assets/img/search/hotel5.jpg',
      'assets/img/search/room-1.jpg',
      'assets/img/search/hotel3.jpg',
    ];
    this.slideList = (this.slides.length === 0) ? s : this.slides;
  },
  mounted() {
  },
  methods: {
    afterChange({ currentSlide }) {
      this.currentIndex = currentSlide;
      if (this.$refs.thumb) this.$refs.thumb.goTo(this.currentIndex);
    },
    showDefaultImage(ev) {
      ev.target.src = '/assets/img/after-booking-img2.jpg';
    },
  },
};
